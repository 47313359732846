<template>
  <div class="sidebar__sidebar" :class="{'sidebar__sidebar--openSidebar': $parent.openSidebar}">
    <div class="sidebar__arrow-right">
      <img src="@/assets/img/arrow.right.png" class="img-fluid" @click="backdrop()">
    </div>
    <router-link :to="{ name: 'home' }">
      <div class="sidebar__logo mb-5">
        <div class="sidebar__logo--img">
          <img src="@/assets/img/api.monitor.text.white.png" class="img-fluid">
        </div>
      </div>
    </router-link>
    <ul>
      <!-- Home -->
      <li class="link-collapse mb-0 py-3">
        <router-link :to="{ name: 'home' }">Home</router-link>
      </li>
      <!-- Monitors -->
      <li class="link-collapse mb-0 pt-3">
        <span v-b-toggle.posts-menu>Monitors
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="posts-menu" role="tabpanel">
          <ul>
            <li>
              <router-link :to="{name: ''}">All Monitors</router-link>
            </li>
            <li @click="backdrop()">
              <router-link :to="{name: ''}">Add New</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Variables -->
      <li class="link-collapse mb-0 pt-3">
        <span v-b-toggle.users-menu>Variables
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="users-menu" role="tabpanel">
          <ul>
            <li @click="backdrop()">
              <router-link :to="{name: ''}">All Variables</router-link>
            </li>
            <li @click="backdrop()">
              <router-link :to="{name: ''}">Add new</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Alerts -->
      <li class="link-collapse mb-0 py-3">
        <router-link :to="{ name: '' }">Alerts</router-link>
      </li>
      <!-- Receirves -->
      <li class="link-collapse mb-0 pt-3">
        <span v-b-toggle.permissions-menu>Receirves
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="permissions-menu" role="tabpanel">
          <ul>
            <li>
              <router-link :to="{name: ''}">All Receirves</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Add New</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Account -->
      <li class="link-collapse mb-0 pt-3">
        <span v-b-toggle.account>Account
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="account" role="tabpanel">
          <ul>
            <li>
              <router-link :to="{name: ''}">View profile</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Delegate access</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Notifications</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Manage plan -->
      <li class="link-collapse mb-0 pt-3">
        <span v-b-toggle.manage-plan>Manage plan
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="manage-plan" role="tabpanel">
          <ul>
            <li>
              <router-link :to="{name: ''}">View plan</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
    
    <b-container fluid class="sidebar-foot">
      <b-row>
        <b-col cols="12" class="text-center social mb-3" align-self="center">
          <img src="@/assets/img/facebook.png" width="40" class="me-3" />
          <img src="@/assets/img/instagram.png" width="40" />
        </b-col>
        <b-col cols="12" class="p-0 text-center text-xl-start">
          <p class="mb-0">
            &copy; {{ currentYear }} Apimonitor24 |
            <a href="#" class="text-decoration-none">apimonitor24.com</a> | Do Genius On, LLC.
            All rights reserved
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  props: { },
  data() {
    return {
      openSidebar: false,
      currentYear: null,
    };
  },
  mounted() {
    let year = new Date();
    this.currentYear = year.getFullYear();
  },

  methods: {
    backdrop() {
      this.$emit('backdrop', false);
    }
  },
};
</script>
