<template>
  <div id="nav-2">
    <b-navbar toggleable="lg" variant="faded" class="px-4 px-md-5 py-3">
      <b-navbar-brand :to="{ name: 'home' }" class="p-0 m-0">
        <img src="@/assets/img/api.monitor.text.png" width="200" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="ms-auto p-0" @click="openSidebar = !openSidebar"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="d-none d-lg-block">
        <b-navbar-nav class="ms-auto pt-3 pt-lg-0">
          <b-nav-item :to="{ name: 'login' }" v-if="!this.$session.exists()">
            <b-button variant="outline-primary" class="ms-auto px-5 w-100 btn-login">Login</b-button>
          </b-nav-item>
          <b-nav-item :to="{ name: 'register' }" v-if="!this.$session.exists()">
            <b-button variant="primary" class="px-5 w-100 btn-sign-up">Sign up</b-button>
          </b-nav-item>
          <b-nav-item :to="{ name: 'logout' }" v-if="this.$session.exists()">
            <b-button variant="primary" class="px-4 w-100">Logout</b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="tabs mb-5 d-none d-lg-block">
      <b-nav tabs align="end" class="px-5 py-2">
        <b-nav-item class="link-subnav" :to="{ name: 'home' }">Home</b-nav-item>
        <b-nav-item class="link-subnav" :to="{ name: 'about_us' }">About Us</b-nav-item>
        <b-nav-item class="link-subnav" v-if="this.$session.exists()" :to="{ name: 'monitors.index' }">Monitors</b-nav-item>
        <b-nav-item class="link-subnav" v-if="this.$session.exists()">Variables</b-nav-item>
        <b-nav-item class="link-subnav" v-if="this.$session.exists()">Alerts</b-nav-item>
        <b-nav-item class="link-subnav" v-if="this.$session.exists()">Receivers</b-nav-item>
        <!-- <b-nav-item-dropdown id="my-nav-dropdown" class="link-subnav" text="Account" no-flip>
          <b-dropdown-item>
            <label>View profile</label>
          </b-dropdown-item>
          <b-dropdown-item>
            <label>Delegate Access</label>
          </b-dropdown-item>
          <b-dropdown-item>
            <label>Notifications</label>
          </b-dropdown-item>
          <b-dropdown-item>
            <label>Settings</label>
          </b-dropdown-item>
          <b-dropdown-item>
            <label>Reset Password</label>
          </b-dropdown-item>
        </b-nav-item-dropdown> -->
        <b-nav-item class="link-subnav" v-if="this.$session.exists()" :to="{ name: 'account_info' }">Account</b-nav-item>
        <b-nav-item class="link-subnav">Manage Plan</b-nav-item>
        <b-nav-item class="link-subnav" @click="$bvModal.show('contact-us')">Contact Us</b-nav-item>
        <b-nav-item class="link-subnav">
          <b-img :src="require('@/assets/img/loupe.png')" width="20"></b-img>
        </b-nav-item>
      </b-nav>
    </div>

    <Sidebar class="d-lg-none" v-on:backdrop="backdropMobile"/>
    <div class="sidebar__sidebar--backdrop" v-if="openSidebar" @click="openSidebar = false"></div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: "Navbar",
  components: { Sidebar },
  data() {
    return { openSidebar: false };
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      window.onscroll = function() {
        let link = document.querySelectorAll('.link-subnav')
        if (window.scrollY > 80) {
          document.querySelector('.tabs').classList.add('bg-tabs-1');
          for (let i = 0; i < link.length; i++) {
            link[i].classList.add('text-link-subnav');
          }
        } else {
          document.querySelector('.tabs').classList.remove('bg-tabs-1');
          for (let i = 0; i < link.length; i++) {
            link[i].classList.remove('text-link-subnav');
          }
        }
      };
    },
    backdropMobile(backdrop) {
      this.openSidebar = backdrop;
    }
  },
};
</script>
