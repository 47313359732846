<template>
  <b-container fluid class="container_footer2 px-4 px-xl-5 py-4 py-md-5">
    <b-row>

      <b-col class="container_footer2__col-1" align-self="center" cols="12" xl="6">
        <b-container class="p-0">
          <b-row class="p-0">
            <b-col cols="12" xl="6" class="p-0 text-center text-xl-start">
              <router-link :to="{ name: 'home' }" class="text-decoration-none">
                <img src="@/assets/img/api.monitor.text.white.png" width="180" class="mb-3 mb-md-4 mb-lg-5" />
              </router-link>
            </b-col>
            <b-col cols="12" class="p-0 text-center text-xl-start d-none d-xl-block">
              <p class="mb-0">
                &copy; {{ currentYear }} Apimonitor24 |
                <a href="#" class="text-decoration-none">apimonitor24.com</a> | Do Genius On, LLC.
                All rights reserved
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col align-self="center" class="container_footer2__col-2 ps-xl-3">
        <b-container class="p-0">
          <b-row class="p-0 d-xl-none">

            <b-col cols="4" class="p-0">
              <router-link :to="{ name: 'about_us' }" class="text-decoration-none">
                <p class="mb-2">About Us</p>
              </router-link>
            </b-col>
            <b-col cols="4" class="p-0">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Api</p>
              </a>
            </b-col>
            <b-col cols="4" class="p-0">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Dashboard</p>
              </a>
            </b-col>
            <b-col cols="4" class="p-0">
              <a @click="$bvModal.show('contact-us')" class="text-decoration-none"
                style="cursor: pointer;">
                <p class="mb-2">Contact</p>
              </a>
            </b-col>
            <b-col cols="4" class="p-0">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Help Docs</p>
              </a>
            </b-col>
            <b-col cols="4" class="p-0">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Frequent questions</p>
              </a>
            </b-col>
            <b-col cols="12" class="text-center social mt-2 mt-md-3 mt-lg-4" align-self="center">
              <img src="@/assets/img/facebook.png" width="30" class="me-3" />
              <img src="@/assets/img/instagram.png" width="30" />
            </b-col>
            <b-col cols="12" class="p-0 text-center text-xl-start copy mt-3 mt-md-4 mt-lg-5">
              <p class="mb-0">
                &copy; {{ currentYear }} Apimonitor24 |
                <a href="#" class="text-decoration-none">apimonitor24.com</a> | Do Genius On, LLC.
                All rights reserved
              </p>
            </b-col>

          </b-row>

          <b-row align-h="end" class="d-none d-xl-flex">
            <b-col cols="6" lg="6" xl="4">
              <router-link :to="{ name: 'about_us' }" class="text-decoration-none">
                <p class="mb-2">About Us</p>
              </router-link>
              <a @click="$bvModal.show('contact-us')" class="text-decoration-none"
                style="cursor: pointer;">
                <p class="mb-2">Contact</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Help Docs</p>
              </a>
            </b-col>
            <b-col cols="6" lg="6" xl="4" class="col-2-2">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Api</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Dashboard</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Frequent questions</p>
              </a>
            </b-col>
            <b-col class="text-center d-none d-xl-block" align-self="center">
              <a href="#">
                <img src="@/assets/img/facebook.png" width="40" class="me-2" />
              </a>
              <a href="#">
                <img src="@/assets/img/instagram.png" width="40" />
              </a>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer2",
  data() {
    return {
      currentYear: null,
    };
  },
  mounted() {
    let year = new Date();
    this.currentYear = year.getFullYear();
  },
};
</script>
