<template>
  <div>
    <Navbar class="d-lg-none"/>
    <b-container fluid class="container_legal_pages pt-5">
      <b-row>
        <div class="container_logo mb-5">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/img/api.monitor.text.white.png" class="img-fluid" />
          </router-link>
        </div>
        <b-col cols="12" class="mb-5">
          <h2 class="text-center text-white fw-bold">Legal pages</h2>
        </b-col>
        <b-col cols="12" class="p-0 links">
          <ul class="justify-content-center">
            <li>
              <router-link :to="{name: 'privacy-policy'}">
                <label :class="{ 'active': $route.name  == 'privacy-policy'}">Privacy Policy</label>
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'terms-service'}">
                <label :class="{ 'active': $route.name  == 'terms-service'}">Terms of Service</label>
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'end-user-agreement'}">
                <label :class="{ 'active': $route.name  == 'end-user-agreement'}">End User Agreement</label>
              </router-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="py-5">
      <b-row>
        <b-col cols="12" class="p-0">
          <router-view/>
        </b-col>
      </b-row>
    </b-container>
    <Footer class="d-none d-xl-block"/>
    <Footer2 class="d-xl-none"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import Footer2 from '@/components/Footer2.vue';

export default {
  components: {
    Navbar,
    Footer,
    Footer2
  },
}
</script>