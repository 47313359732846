<template>
  <b-container fluid class="px-5 py-5">
    <b-row class="container_footer">
      <b-col class="container_footer__col-1" align-self="center"
        cols="12" md="6" lg="6" xl="6" order="2" order-md="1">
        <b-container class="p-0">
          <b-row class="p-0">
            <b-col cols="6" class="p-0">
              <router-link :to="{ name: 'home' }" class="text-decoration-none">
                <img src="@/assets/img/api-monitor.png" width="180" class="mb-3" />
              </router-link>
            </b-col>
            <b-col cols="6" class="text-center d-xl-none" align-self="center">
              <img src="@/assets/img/facebook.png" width="40" class="me-2" />
              <img src="@/assets/img/instagram.png" width="40" />
            </b-col>
            <b-col cols="12" class="p-0 text-center text-xl-start">
              <p class="mb-0">
                &copy; {{ currentYear }} Apimonitor24 |
                <a href="#" class="text-decoration-none">apimonitor24.com</a> | Do Genius On, LLC.
                All rights reserved
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col align-self="center" class="container_footer__col-2 ps-md-5 ps-xl-3" order="1" order-md="2">
        <b-container>
          <b-row align-h="end">
            <b-col cols="6" lg="6" xl="4">
              <router-link :to="{ name: 'about_us' }" class="text-decoration-none">
                <p class="mb-2">About Us</p>
              </router-link>
              <a @click="$bvModal.show('contact-us')" class="text-decoration-none"
                style="cursor: pointer;">
                <p class="mb-2">Contact</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Help Docs</p>
              </a>
            </b-col>
            <b-col cols="6" lg="6" xl="4" class="col-2-2">
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Api</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Dashboard</p>
              </a>
              <a href="#" class="text-decoration-none">
                <p class="mb-2">Frequent questions</p>
              </a>
            </b-col>
            <b-col class="text-center d-none d-xl-block" align-self="center">
              <a href="#">
                <img src="@/assets/img/facebook.png" width="40" class="me-2" />
              </a>
              <a href="#">
                <img src="@/assets/img/instagram.png" width="40" />
              </a>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: null,
    };
  },
  mounted() {
    let year = new Date();
    this.currentYear = year.getFullYear();
  },
};
</script>
